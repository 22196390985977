import { createRouter, createWebHistory } from "vue-router";
import { useCounterStore } from "@/store/index.js";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  routes: [
    {
      name: "Landing",
      path: "/",
      component: () => import("@/views/LandingPage/index"),
      children: [
        // {
        //   name: "500",
        //   path: "500",
        //   component: () => import("@/views/Pages/500"),
        // },
        // {
        //   name: "429",
        //   path: "429",
        //   component: () => import("@/views/Pages/429"),
        // },
        {
          name: "onlineVerification",
          path: "verify/:id",
          props: true,
          component: () => import("@/views/Pages/onlineVerification"),
        },
        {
          name: "verifyData",
          path: "verifyData/:id",
          props: true,
          component: () => import("@/views/Pages/verifyData"),
        },
        {
          name: "verifySubmit",
          path: "verifySubmit",
          component: () => import("@/views/Pages/verifySubmit"),
        },
        {
          name: "verifyDone",
          path: "verifyDone",
          component: () => import("@/views/Pages/verifyDone"),
        },
        // {
        //   name: "test",
        //   path: "test",
        //   component: () => import("@/views/Pages/test"),
        // },
        {
          name: "delete",
          path: "delete",
          component: () => import("@/views/Pages/delete"),
        },
        {
          name: "home",
          path: "",
          component: () => import("@/views/LandingPage/home"),
        },
        {
          name: "aboutPage",
          path: "aboutPage",
          component: () => import("@/views/LandingPage/aboutPage/aboutPage"),
        },
        {
          name: "Q&APage",
          path: "Q&APage",
          component: () => import("@/views/LandingPage/Q&APage/Q&APage"),
        },
        {
          beforeEnter: (to, from, next) => {
            const store = useCounterStore();
            const token = store.accessToken;

            if (token) {
              next();
            } else {
              next({
                name: "loginUser",
              });
            }
          },
          name: "transaction",
          path: "transaction/:id",
          props: true,
          component: () =>
            import("@/views/LandingPage/transaction/transaction"),
        },
        {
          beforeEnter: (to, from, next) => {
            const store = useCounterStore();
            const token = store.accessToken;

            if (token) {
              next();
            } else {
              next({
                name: "loginUser",
              });
            }
          },
          name: "transFamily",
          path: "transFamily/:id",
          props: true,
          component: () =>
            import("@/views/LandingPage/transaction/transFamily"),
        },
        {
          beforeEnter: (to, from, next) => {
            const store = useCounterStore();
            const token = store.accessToken;

            if (token) {
              next();
            } else {
              next({
                name: "loginUser",
              });
            }
          },
          name: "previwe",
          path: "previwe",
          component: () => import("@/views/LandingPage/previwe/previwe"),
        },
        {
          beforeEnter: (to, from, next) => {
            const store = useCounterStore();
            const token = store.accessToken;

            if (token) {
              next();
            } else {
              next({
                name: "loginUser",
              });
            }
          },
          name: "finishReview",
          path: "finishReview",
          component: () =>
            import("@/views/LandingPage/finishReview/finishReview"),
        },
        {
          beforeEnter: (to, from, next) => {
            const store = useCounterStore();
            const token = store.accessToken;

            if (token) {
              next();
            } else {
              next({
                name: "loginUser",
              });
            }
          },
          name: "waitingReview",
          path: "waitingReview",
          component: () =>
            import("@/views/LandingPage/waitingReview/waitingReview"),
        },
        {
          beforeEnter: (to, from, next) => {
            const store = useCounterStore();
            const token = store.accessToken;

            if (token) {
              next();
            } else {
              next({
                name: "loginUser",
              });
            }
          },
          name: "reservation",
          path: "reservation",
          component: () =>
            import("@/views/LandingPage/reservation/reservation"),
        },
        {
          beforeEnter: (to, from, next) => {
            const store = useCounterStore();
            const token = store.accessToken;

            if (token) {
              next();
            } else {
              next({
                name: "loginUser",
              });
            }
          },
          name: "verifList",
          path: "verifList",
          component: () => import("@/views/LandingPage/verif/verifList"),
        },
        {
          beforeEnter: (to, from, next) => {
            const store = useCounterStore();
            const token = store.accessToken;

            if (token) {
              next();
            } else {
              next({
                name: "loginUser",
              });
            }
          },
          name: "familyList",
          path: "familyList",
          component: () => import("@/views/LandingPage/familyList/familyList"),
        },
        {
          beforeEnter: (to, from, next) => {
            const store = useCounterStore();
            const token = store.accessToken;

            if (token) {
              next();
            } else {
              next({
                name: "loginUser",
              });
            }
          },
          name: "statusForm",
          path: "statusForm",
          component: () => import("@/views/LandingPage/statusForm/statusForm"),
        },
        {
          beforeEnter: (to, from, next) => {
            const store = useCounterStore();
            const token = store.accessToken;

            if (token) {
              next();
            } else {
              next({
                name: "loginUser",
              });
            }
          },
          name: "editDate",
          path: "editDate",
          component: () => import("@/views/LandingPage/editDate/editDate"),
        },
        {
          // beforeEnter: (to, from, next) => {
          //   const store = useCounterStore();
          //   const token = store.token;

          //   if (token) {
          //     next();
          //   } else {
          //     next({
          //       name: "loginUser",
          //     });
          //   }
          // },
          name: "checkExist",
          path: "checkExist",
          component: () => import("@/views/LandingPage/transaction/checkExist"),
        },
        {
          name: "loginUser",
          path: "loginUser",
          component: () => import("@/views/LandingPage/transaction/loginUser"),
        },
        {
          name: "apps",
          path: "apps",
          component: () => import("@/views/LandingPage/apps"),
        },
      ],
    },

    {
      path: "/:catchAll(.*)",
      name: "error",
      component: () => import("@/views/Pages/index"),
      children: [
        {
          name: "404 Error",
          path: "",
          component: () => import("@/views/Pages/errorPage"),
        },
      ],
    },
  ],
});

export default router;
