import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import i18n from "./i18n";
import axios from "axios";
import VueAxios from "vue-axios";
import { vsxIcon } from "vue-iconsax";
import { MotionPlugin } from "@vueuse/motion";
import vuetify from "./plugins/vuetify";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import VueTheMask from "vue-the-mask";
import { useCounterStore } from "./store/index";
import Swal from "sweetalert2";
import "@fortawesome/fontawesome-free/css/all.css";

let isRefreshing = false;
let refreshSubscribers = [];

function onRefreshed(token) {
  refreshSubscribers.forEach((subscriber) => subscriber(token));
  refreshSubscribers = [];
}
function addRefreshSubscriber(subscriber) {
  refreshSubscribers.push(subscriber);
}
axios.defaults.baseURL = process.env.VUE_APP_URL;
axios.interceptors.response.use(
  function (response) {
    if (response.status !== 401) {
      return response;
    } else {
      router.push({ name: "loginUser" });
    }
  },
  function (error) {
    if (error.response.status === 401) {
      const store = useCounterStore();
      const config = error.config;

      if (!isRefreshing) {
        isRefreshing = true;
        axios
          .post("auth/refresh_token", {
            refreshToken: localStorage.getItem("refreshToken"),
          })
          .then((res) => {
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            store.refreshToken = res.data.refreshToken;
            store.accessToken = res.data.accessToken;

            isRefreshing = false;
            onRefreshed(res.data.accessToken);
          })
          .catch(() => {
            Swal.fire({
              title: "أنتهت صلاحية الجلسة، الرجاء قم بإعادة المحاولة.",
              icon: "error",
              timer: 3500,
              position: "top-end",
              toast: true,
              showConfirmButton: false,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            router.push({ name: "loginUser" });
            isRefreshing = false;
          });
        return new Promise((resolve, reject) => {
          addRefreshSubscriber((token) => {
            config.headers.Authorization = `${token}`;
            resolve(axios.request(config));
          });
        });
      }
    }
     if (error.response.status === 429) {
      Swal.fire({
        title: "الكثير من المحاولات , يرجى المحاولة بعد ساعة",
        icon: "error",
        timer: 3500,
        position: "top-end",
        toast: true,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    } 
     if (error.response.status === 500 || error.response.status === 501) {
      Swal.fire({
        title: "الكثير من الطلبات على الخادم يرجى المحاولة في وقت اخر",
        icon: "error",
        timer: 3500,
        position: "top-end",
        toast: true,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    }
    return Promise.reject(error);
  }
);

createApp(App)
  .component("vsxIcon", vsxIcon)
  .component("VueDatePicker", VueDatePicker)
  .use(MotionPlugin)
  .use(VueAxios, axios)
  .use(i18n)  
  .use(createPinia())
  .use(router)
  .use(vuetify)
  .use(VueTheMask)
  .mount("#app");
