import { defineStore } from "pinia";
import router from "@/router/index";

export const useCounterStore = defineStore("counter", {
  state: () => ({
    loading: false,
    rail: true,
    drawer: false,
    userInfo: JSON.parse(localStorage.getItem("userInfo")),
    userPhoneNumber: null,
    userToken: localStorage.getItem("userToken"),
    accessToken: localStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken"),
    accessToken: localStorage.getItem("accessToken"),
    cardInfo: JSON.parse(localStorage.getItem("cardInfo")),
    isOTP: 0,
    userCardNumber: null,
    isDark: localStorage.getItem("isDark"),
    verifIndex: 0,
    verifPageIndex: 0,
    verifInfo: {},
    verifOldid: false,
    location: { lat: null, lon: null },
  }),
  getters: {
    isRail(state) {
      if (state.rail === true) return true;
      return false;
    },
  },
  actions: {
    railChange() {
      this.rail = !this.rail;
    },
    drawerChange() {
      this.drawer = !this.drawer;
    },
    login(data) {
      localStorage.setItem("userInfo", JSON.stringify(data));
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      this.accessToken = data.accessToken;
      this.refreshToken = data.refreshToken;
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      router.push("/admin/dashboard");
    },
    logout() {
      localStorage.clear();
      this.accessToken = null;
      this.refreshToken = null;

      router.push("/auth");
    },
  },
});
